<!-- 店铺认领 -->
<template>
  <div class="do-page-storeclaim">
    <base-nav-bar
      @click-left="$router.go(-1)"
      left-arrow
      :title="route.query?.type === 'claim' ? '店铺认领' : '新增'"
    >
    </base-nav-bar>
    <!-- 上传图片 拍照/从手机相册选择 -->
    <camera-image
      v-model:show="cameraImageBol"
      @uploadImage="uploadImage"
      @close="cameraImageBol = false"
    >
      <div class="example-img">
        <!-- cameraImageType  scene（现场照片）  business（营业执照 ） -->
        <img
          :src="
            cameraImageType === 'scene'
              ? require('@/assets/images/my/location.png')
              : require('@/assets/images/my/business.png')
          "
        /></div
    ></camera-image>
    <base-bscroll
      :safeareaNavbar="true"
      @refScroll="refScroll"
      :options="{ bounce: true, firstChildrenHeight: true }"
      :style="{
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        height: `calc(100vh - ${
          // 高度状态样 + 头部导航 + 底部按钮 + 底部间距
          $Hbuild.getStatusBarHeight() + $ComJs.bili * 2.2 + 'px'
        })`,
      }"
    >
      <div class="do-page-storeclaim-content">
        <!-- 店铺名称 -->
        <div class="do-storeclaim-fromitem">
          <div class="title">*店铺名称</div>
          <van-field
            v-model="fromParames.name"
            @blur="checkoutFrom('name')"
            placeholder="请输入店铺名称"
          />
          <div v-if="fromError.name" class="error-tip">
            {{ fromError.name }}
          </div>
        </div>
        <!-- 店铺地址 -->
        <div class="do-storeclaim-fromitem">
          <div class="title">*店铺地址</div>
          <van-field
            v-model="fromParames.address"
            @blur="checkoutFrom('address')"
            placeholder="请输入店铺地址"
            :rules="[{ required: true, message: '请填写店铺地址' }]"
          />
          <div v-if="fromError.address" class="error-tip">
            {{ fromError.address }}
          </div>
          <!-- 地图选择位置 -->
          <div class="storeclaim-map" @click="toLocation">
            <!-- 地理位置静态图 -->
            <img
              v-if="staticMapImage && fromParames.coord"
              :src="staticMapImage"
            />
            <!-- 未选择显示默认图 -->
            <div class="overlay-wrap" v-else>
              <div class="overlay"></div>
              <img
                src="https://cdn.ekeliu.com/202301/png/dc65039bdf2fc4290d87cb1785b40f5c.png"
              />
              <span>在地图上选择位置</span>
            </div>
          </div>
          <div v-if="fromError.coord" class="error-tip">
            {{ fromError.coord }}
          </div>
          <div class="tip">位置错误会影响数据分析，请仔细阅读。</div>
        </div>
        <!-- 现场图片 -->
        <div class="do-storeclaim-fromitem">
          <div class="title">*现场照片</div>
          <div v-if="sceneImage" class="scene-image-wrap">
            <!-- 关闭 -->
            <div
              class="close"
              @click="
                sceneImage = '';
                fromError.sceneImage = '请填写现场照片';
              "
            >
              <van-icon name="cross" size="0.18rem" color="#FFFFFF" />
            </div>
            <img :src="sceneImage" class="scene-image" />
          </div>
          <!-- 默认图片 -->
          <div v-else class="up-img" @click="openCameraImage('scene')">
            <van-icon name="photograph" color="#DCDEE0" size="0.4rem" />
          </div>
          <div v-if="fromError.sceneImage" class="error-tip">
            {{ fromError.sceneImage }}
          </div>

          <div class="tip">上传现场照片，确保店铺名称清晰</div>
        </div>
        <div class="do-storeclaim-fromitem">
          <div class="title">*营业执照</div>
          <van-field
            v-model="fromParames.businessCode"
            @blur="checkoutFrom('businessCode')"
            :style="{ marginBottom: '0.16rem' }"
            placeholder="输入统一社会信用代码"
          />
          <div
            v-if="fromError.businessCode"
            :style="{ marginBottom: '0.16rem' }"
            class="error-tip"
          >
            {{ fromError.businessCode }}
          </div>
          <div v-if="businessImage" class="scene-image-wrap">
            <div
              class="close"
              @click="
                businessImage = '';
                fromError.businessImage = '请填写营业执照照片';
              "
            >
              <van-icon name="cross" size="0.18rem" color="#FFFFFF" />
            </div>
            <img :src="businessImage" class="scene-image" />
          </div>
          <div v-else class="up-img" @click="openCameraImage('business')">
            <van-icon name="photograph" color="#DCDEE0" size="0.4rem" />
          </div>
          <div v-if="fromError.businessImage" class="error-tip">
            {{ fromError.businessImage }}
          </div>
          <div class="tip">位置错误会影响数据分析，请仔细阅读。</div>
        </div>
      </div>
    </base-bscroll>
    <div class="submit-wrap">
      <van-button
        v-if="route.query?.type === 'claim'"
        :class="{ 'curr-btn': submitCurrBol }"
        @click="sUbmit"
        type="default"
        :loading="loading"
      >
        提交认领</van-button
      >
      <van-button
        v-else
        :class="{ 'curr-btn': submitCurrBol }"
        @click="nextSUbmit"
        type="default"
      >
        下一步</van-button
      >
    </div>
  </div>
</template>
<script setup>
import { nextTick, ref, computed, onActivated } from "vue";
import { sendConfirmShops } from "@/axios/user";
import { sendStaticMap } from "@/axios/common";
import cameraImage from "./camera-image.vue";
import { Toast } from "vant";
import { useStore } from "vuex";
import { useRouter, useRoute } from "vue-router";
import { onBeforeRouteLeave } from "vue-router";
const store = useStore();
const router = useRouter();
const route = useRoute();

// cameraImage 上传图片弹窗显示
const cameraImageBol = ref(false);
// cameraImage类型 scene店铺门面  business营业执照
const cameraImageType = ref("scene");
// 现场图片
const sceneImage = ref("");
// 营业执照图片
const businessImage = ref("");
// 地图位置静态图
const staticMapImage = ref("");
const loading = ref(false);
let scrollRef = null;

// 错误信息对象
const fromError = ref({});
// 提交参数
const fromParames = ref({
  name: "", // 店铺名称
  coord: "", // 地图地址
  address: "", // 店铺地址
  businessCode: "", // 营业执照
  poiInfo: "", // 环境信息
});

// 页面激活状态
onActivated(() => {
  console.log("aswfasf", window.history.state, route);

  if (route.query?.type === "new") {
    clearData();
  }

  const query = route.query;
  if (query && query.data) {
    const data = JSON.parse(query.data);
    if (data) {
      fromParames.value = { ...fromParames.value, ...data };
      if (data.coord) {
        fromError.value.coord = "";
        // 获取位置图片
        getsStaticMapImg(data.coord);
      }
    }
    if (scrollRef) scrollRef.refresh();
  }
});

// 下一步
function nextSUbmit() {
  checkoutFrom();
  if (submitCurrBol.value) {
    const { name, coord, address, businessCode, poiInfo } = fromParames.value;
    router.push({
      path: "/my/store-acquisition-signal",
      query: {
        data: JSON.stringify({
          shop_name: name,
          shop_address: address,
          live_pic: sceneImage.value,
          business_license_pic: businessImage.value,
          coord: coord,
          usci: businessCode,
          poi_info: poiInfo,
        }),
      },
    });
  }
}

async function sUbmit() {
  if (!submitCurrBol.value) {
    return false;
  }
  try {
    const { name, coord, address, businessCode, poiInfo } = fromParames.value;
    console.log("poiInfo?.id", poiInfo?.id);
    loading.value = true;
    const { data, code } = await sendConfirmShops({
      shop_name: name,
      shop_address: address,
      live_pic: sceneImage.value,
      business_license_pic: businessImage.value,
      coord: coord,
      usci: businessCode,
      poi_id: poiInfo?.id,
      poi_info: {},
      type: 1,
    });
    if (code === 0 && data) {
      Toast.success("新增成功");
      // 暂无数据
      router.replace("/my/store-management");
    }
    loading.value = false;
  } catch (error) {
    if (error) {
      loading.value = false;

      Toast({
        position: "bottom",
        message: error.msg || "服务端错误 ",
      });
    }
  }
}

// 根据经纬度获取位置静态图
async function getsStaticMapImg(coord) {
  const res = await sendStaticMap({ coord });
  if (res && res.code === 0 && res.data) {
    staticMapImage.value = res.data.uri;
  }
}

// 选择位置
function toLocation() {
  router.replace({
    path: "/my/store-location",
    query: {
      type: route.query?.type,
      location: store.state.common.coordinate || "22.526977,114.055388",
    },
  });
}

//上传图片
async function uploadImage(imgPath) {
  if (cameraImageType.value === "scene") {
    sceneImage.value = imgPath;
    checkoutFrom("sceneImage");
  } else if (cameraImageType.value === "business") {
    businessImage.value = imgPath;
    checkoutFrom("businessImage");
  }
  cameraImageBol.value = false;
}

// cameraImage 上传图片弹窗显示
function openCameraImage(type) {
  cameraImageType.value = type;
  cameraImageBol.value = true;
}

// 提交按钮高亮，验证参数，和错误信息 bot 值
const submitCurrBol = computed(() => {
  const msg = fromError.value;
  const parames = fromParames.value;
  let _currSubmitBol = true;
  // 错误msg 中是否有消息
  Object.keys(msg).map((item) => {
    if (msg[item]) _currSubmitBol = false;
  });

  // 参数里面是否都为空
  let paramesNotBol = true;
  Object.keys(parames).map((item) => {
    if (!parames[item] && item !== "poiInfo") {
      paramesNotBol = false;
    }
  });
  if (!sceneImage.value || !businessImage.value) {
    return false;
  }
  return paramesNotBol && _currSubmitBol;
});

// 检查参数
function checkoutFrom(type) {
  const { name, coord, address, businessCode } = fromParames.value;
  const msg = { ...fromError.value };
  const checkout = {
    name: () => {
      if (!name) msg.name = "请填写店铺名称";
      else msg.name = "";
    },
    address: () => {
      if (!address) msg.address = "请填写店铺地址";
      else msg.address = "";
      console.log("address", msg);
    },
    coord: () => {
      if (!coord) msg.coord = "请填写地图位置";
      else msg.coord = "";
    },
    businessCode: () => {
      if (!businessCode) msg.businessCode = "请填写营业执照";
      else msg.businessCode = "";
    },
    sceneImage: () => {
      if (!sceneImage.value) msg.sceneImage = "请填写现场照片";
      else msg.sceneImage = "";
    },
    businessImage: () => {
      if (!businessImage.value) msg.businessImage = "请填写营业执照照片";
      else msg.businessImage = "";
    },
  };
  if (checkout[type]) {
    checkout[type]();
  } else {
    Object.keys(checkout).map((item) => checkout[item]());
  }
  fromError.value = msg;

  nextTick(() => {
    if (scrollRef) scrollRef.refresh();
  });
}

function refScroll(scroll) {
  scrollRef = scroll;
}

// 清空数据
function clearData() {
  fromParames.value = {
    name: "", // 店铺名称
    coord: "", // 地图地址
    address: "", // 店铺地址
    businessCode: "", // 营业执照
    poiInfo: "", // 环境信息
  };
  businessImage.value = "";
  staticMapImage.value = "";
  sceneImage.value = "";
  cameraImageType.value = "";
  fromError.value = {};
}

onBeforeRouteLeave((to, from, next) => {
  if (to.name === "my-store-management") {
    clearData();
  }
  next();
});
</script>

<style lang="scss">
.storedelete-dialog {
  .van-dialog__message {
    font-size: 0.28rem;
    font-weight: 400;
    color: #969799;
  }
  .van-dialog__cancel {
    color: #0057ff !important;
  }

  .van-dialog__confirm {
    color: #323233;
  }
}
.do-page-storeclaim {
  &-content {
    padding: 0.24rem 0 0.4rem 0;
    // padding-bottom: calc(0.24rem + constant(safe-area-inset-bottom));
    // padding-bottom: calc(0.24rem + env(safe-area-inset-bottom));
  }

  .do-storeclaim-fromitem {
    padding: 0.24rem 0.16rem;
    background: #ffffff;
    border-radius: 0.16rem;
    margin: 0 0.24rem;
    margin-bottom: 0.24rem;

    .error-tip {
      color: red;
      font-size: 0.24rem;
      font-weight: 400;
      margin-top: 0.08rem;
    }

    .title {
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.85);
      margin-bottom: 0.16rem;
    }
    .van-field {
      background: rgba(2, 4, 15, 0.04);
      border-radius: 0.16rem;
      padding: 0.28rem 0.24rem;
      input {
        &::placeholder {
          font-size: 0.28rem;
          font-weight: 400;
          color: rgba(3, 5, 15, 0.25);
        }
      }
    }
    .storeclaim-map {
      height: 2.56rem;
      border-radius: 0.16rem;
      margin-top: 0.24rem;
      position: relative;
      img {
        width: 100%;
        height: 100%;
      }
      .overlay-wrap {
        span {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          color: #ffffff;
          font-size: 0.24rem;
          z-index: 111;
          font-weight: 400;
        }
        img {
          width: 100%;
        }
      }
      .overlay {
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 0.16rem;
        z-index: 100;
      }
    }
    .tip {
      font-size: 0.28rem;
      font-weight: 400;
      color: rgba(3, 5, 15, 0.25);
      margin-top: 0.24rem;
    }
    .up-img {
      width: 1.6rem;
      height: 1.6rem;
      background: #f5f5f6;
      border-radius: 0.16rem;
      margin-top: 0.08rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .scene-image-wrap {
      position: relative;
      width: 1.6rem;
      .close {
        position: absolute;
        right: 0;
        display: flex;
        z-index: 10;
        justify-content: center;
        align-items: center;
        background: #484848;
        width: 0.32rem;
        height: 0.32rem;
        border-radius: 0 0.16rem 0 0.24rem;
      }
    }
    .scene-image {
      border-radius: 0.16rem;
      width: 1.6rem;
      height: 1.6rem;
    }
  }
  .submit-wrap {
    position: fixed;
    bottom: 0;
    width: 100%;
    background: #ffffff;
    display: flex;
    padding-top: 0.14rem;
    padding-bottom: calc(0.3rem + constant(safe-area-inset-bottom));
    padding-bottom: calc(0.3rem + env(safe-area-inset-bottom));
    button {
      width: 100%;
      margin: 0 0.24rem;
      background: #c0c0c3;
      border-radius: 0.16rem;
      font-size: 0.32rem;
      font-weight: 500;
      color: #ffffff;
    }
    .curr-btn {
      background: #0057ff;
      color: #ffffff;
    }
  }
  .store-sheet-action {
    .content {
      .split {
        height: 0.16rem;
        background: #f7f8fa;
      }
      .example-img {
        display: flex;
        justify-content: center;
        padding-bottom: 0.16rem;
        img {
          height: 3.78rem;
        }
      }

      .fn-wrap {
        &-item {
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 0.32rem;
          font-weight: 400;
          color: #02040f;
          padding: 0.28rem 0;
          .icon {
            width: 0.32rem;
            height: 0.32rem;
            margin-right: 0.08rem;
          }
        }
      }
      .cache-btn {
        font-size: 0.32rem;
        font-weight: 400;
        color: rgba(2, 4, 15, 0.45);
      }
    }
  }
}
</style>
