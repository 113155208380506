<!-- 店铺认领 -->
<template>
  <van-action-sheet
    :closeable="false"
    class="store-sheet-action"
    title="店铺门面示例"
  >
    <div class="content">
      <slot></slot>
      <div class="split"></div>
      <div class="fn-wrap">
        <div class="fn-wrap-item" @click="getImage">
          <img class="icon" :src="require('@/assets/images/my/camera.png')" />
          拍照
        </div>
        <div class="fn-wrap-item" @click="galleryImg">从手机相册选择</div>
      </div>
      <div class="split"></div>
      <div class="fn-wrap">
        <div class="fn-wrap-item cache-btn" @click="emits('close')">取消</div>
      </div>
    </div>
    <!-- <img id="storeActionImg" :src="imgPath111" crossorigin="anonymous" /> -->
  </van-action-sheet>
</template>
<script setup>
import { defineEmits, ref } from "vue";
import { Toast, Dialog } from "vant";
const imgPath111 = ref("");
/** 获取抽屉DOM */
const emits = defineEmits(["close", "uploadImage"]); // 抽屉配置

//拍照
function getImage() {
  if (!window.plus) return;
  Dialog.confirm({
    title: "授权提示",
    message: "获取相机，以便您拍摄上传店铺信息",
  })
    .then(() => {
      // on confirm
      const plus = window.plus;
      var c = plus.camera.getCamera();
      c.captureImage(
        function (e) {
          console.log("e", e);
          plus.io.resolveLocalFileSystemURL(
            e,
            function (entry) {
              console.log(" entry.toLocalURL()", entry.toLocalURL());
              const path = entry.toLocalURL();
              var file = path.substr(path.lastIndexOf("/") + 1);
              fileUploader(path, file);
            },
            function (e) {
              Toast({
                position: "bottom",
                message: "读取拍照文件错误。请使用本地相册选择",
              });
            }
          );
        },
        function (s) {
          try {
            if (
              plus.os.name === "iOS" &&
              s.message === "No filming permission"
            ) {
              Toast({
                position: "bottom",
                message: "请到设置中开启相机访问，以便拍摄上传信息",
              });
            } else if (plus.os.name === "Android") {
              Toast({
                position: "bottom",
                message: "请到设置中开启相机访问，以便拍摄上传信息",
              });
            }
          } catch (error) {
            Toast({
              position: "bottom",
              message: s.message,
            });
          }
        },
        {}
      );
    })
    .catch(() => {
      // on cancel
    });
}

//本地相册选择
function galleryImg() {
  if (!window.plus) return;
  Dialog.confirm({
    title: "授权提示",
    message: "获取相册访问，以便您拍摄上传店铺信息",
  })
    .then(() => {
      // on confirm
      const plus = window.plus;
      plus.gallery.pick(
        function (path) {
          var file = path.substr(path.lastIndexOf("/") + 1);
          fileUploader(path, file);
        },
        function (e) {
          try {
            if (plus.os.name === "iOS" && e.code === 8) {
              Toast({
                position: "bottom",
                message: "请到设置中开启相册访问，以便选择资料上传",
              });
            } else if (plus.os.name === "Android") {
              Toast({
                position: "bottom",
                message: e.message,
              });
            }
          } catch (error) {
            Toast({
              position: "bottom",
              message: e.message,
            });
          }
        },
        {
          filter: "image",
        }
      );
    })
    .catch(() => {
      // on cancel
    });
}

// 上传图片到华为云
function fileUploader(path, file) {
  if (!window.plus) return;
  const plus = window.plus;
  const url = process.env.VUE_APP_BASE_API + "/api/v1/common/upload";
  const uploader = plus.uploader.createUpload(
    url,
    {
      method: "POST",
    },
    function (up, state) {
      var xhrData = JSON.parse(up.responseText);
      if (state == 200) {
        const res = xhrData;
        const download_url = res.data.download_url;
        console.log("上传成功" + JSON.stringify(res));
        emits("uploadImage", download_url);
      } else {
        Toast("上传图片失败 - " + state);
        console.log("上传失败 - " + state);
      }
    }
  );
  uploader.addData("key", file);
  uploader.addData("token", "token"); //添加上传参数  token必填
  uploader.addFile(path, {
    key: "file",
  });
  uploader.start();
}
</script>

<style lang="scss">
.store-sheet-action {
  max-height: 8.6rem;
  // padding-bottom: calc(constant(safe-area-inset-bottom));
  // padding-bottom: calc(env(safe-area-inset-bottom));
  .content {
    .split {
      height: 0.16rem;
      background: #f7f8fa;
    }
    .example-img {
      display: flex;
      justify-content: center;
      padding-bottom: 0.16rem;
      img {
        height: 3.78rem;
      }
    }

    .fn-wrap {
      &-item {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 0.32rem;
        font-weight: 400;
        color: #02040f;
        padding: 0.28rem 0;
        .icon {
          width: 0.32rem;
          height: 0.32rem;
          margin-right: 0.08rem;
        }
      }
    }
    .cache-btn {
      font-size: 0.32rem;
      font-weight: 400;
      color: rgba(2, 4, 15, 0.45);
    }
  }
}
</style>
